*,
*::before,
*::after {
  box-sizing: border-box;
}
:focus,
:hover,
:active {
  outline: none;
}
body {
  margin: 0;
  overflow: auto;
  background: #090909;
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Mono", monospace;
}

.app-wrapper {
  padding: 15px;
  display: flex;
  align-items: center;
  flex: 1;
}

.app-wrapper .left {
  /* max-width: 900px; */
  width: 80%;
  margin: 0 auto;
}

.highlight-terminal-text {
  font-size: 22px;
  font-weight: lighter;
  line-height: 25px;
}

#app {
}

.error {
  color: #eb4b3b;
}

#terminal {
  max-width: 100%;
  /* height: 400px; */
  transition: 0.2s;
  border-radius: 5px;
  padding: 12px 10px 12px 12px;
  height: 75vh;
}
#window {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: default;
}
#title,
#field {
  font-size: 14px;
}

#field {
  height: 100%;
  padding: 5px;
  overflow: auto;
  /* 	I'd like to get white-space: break-spaces to work
		but it won't for some reason. In the meantime,
		overflow-wrap: break-word will have to do. */
  overflow-wrap: break-word;
}
#field::-webkit-scrollbar {
  width: 4px;
}
#field.dark::-webkit-scrollbar-thumb {
  background-color: black;
}
#field.light::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 50px;
}
#field > div {
  min-height: 20px;
  width: 100%;
  cursor: default;
}
#input-container {
  display: flex;
}
#query,
#cursor {
  display: inline-block;
}
#query {
  margin-right: 10px;
  white-space: pre-line;
  color: #808080;
}

.main-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  text-align: center;
  font-size: 14px;
}

#cursor {
  position: relative;
  bottom: -2px;
  left: 2px;
  width: 0.5rem;
  height: 3px;
}
@keyframes blink-dark {
  0%,
  100% {
    background-color: #f4f4f4;
  }
  50% {
    background-color: transparent;
  }
}
@keyframes blink-light {
  0%,
  100% {
    background-color: #474554;
  }
  50% {
    background-color: transparent;
  }
}

@media only screen and (max-width: 600px) {
  .highlight-terminal-text {
    font-size: 20px;
    font-weight: lighter;
    line-height: 22px;
  }

  #terminal {
    padding: 6px;
  }
  @media only screen and (max-width: 600px) {
    .highlight-terminal-text {
      font-size: 20px;
      font-weight: lighter;
      line-height: 22px;
    }

    #terminal {
      padding: 6px;
    }

    .header div img {
      max-width: 80px !important;
      padding-top: 15px;
    }
  }
}

.res-menu {
  list-style: none;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.res-menu li {
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .res-menu {
    display: flex;
  }
}

.notfound img {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.abouteme {
  display: flex;
  height: 300px;
  border: 1px solid gray;
  width: 70% !important;
  align-items: center;
}
.abouteme img {
  padding: 15px;
  height: 300px;
}
.abouteme .desc {
  line-height: 20px;
}

#eyes, #eyes img {
  position: absolute;
}

.rick-cont {
	position: relative;
}