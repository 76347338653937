.header div{
  display: flex;
  align-items: center;
  color: white;
}

.header div img{ 
  display: flex;
  align-items: center;
  max-width: 100px;
  padding-top: 10px;
}

/* Track */

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

/* .header {
  .menu {
    .menu___wrapper {
      background: red;
      a {
        display: block;
        p {
          margin: 0;
        }
      }
    }
  }
} */
